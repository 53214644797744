import { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { getComponentFromRT, RichTextType } from 'helpers'
import { SinglePageWrapper } from 'components/layout/SinglePageWrapper'

export interface PrivacyPageProps {
  data: {
    page: {
      sectionOne: RichTextType
    }
  }
}

export const PrivacyPage: FunctionComponent<PrivacyPageProps> = ({ data }) => (
  <SinglePageWrapper>
    <div className="wrapper">{getComponentFromRT(data.page.sectionOne)}</div>
  </SinglePageWrapper>
)

export default PrivacyPage

export const query = graphql`
  query PrivacyQuery {
    page: contentfulPage(name: { eq: "privacy" }) {
      sectionOne {
        raw
      }
    }
  }
`
